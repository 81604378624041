.entry-advantages {
  width: 40%;
  display: flex;
  justify-content: space-between;
  &__item {
    color: #202531;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;

    &::before {
      position: relative;
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: #3b99cc;
      margin-right: 10px;
    }
  }
}

@media (max-width: 800px) {
  .entry-advantages {
    width: 60%;
  }
}

@media (max-width: 575px) {
  .entry-advantages {
    width: 100%;
    margin-top: 10px;

    &__item {
      margin-right: 5px;
      font-size: 14px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
