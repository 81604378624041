.analytics-stand-geography {
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  margin-bottom: 16px;
  &__header {
    display: flex;
    justify-content: space-between;
    background: #f7f8fc;
    align-items: center;
    background: #f7f8fc;
    border-radius: 4px 4px 0px 0px;
    padding: 11px 15px;
  }
  &__title {
    font-size: 18px;
    color: rgba(32, 37, 49, 1);
  }
  &__more-btn {
    background: #ffffff;
    border: 1px solid #e9ecf5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
  }
  &__main {
    padding: 17px 47px 28px 47px;
  }

  &__chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__participants {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & h3 {
      color: #3f4551;
      font-weight: 500;
      font-size: 32px;
    }
    & span {
      font-size: 13px;
      color: #727e8e;
    }
  }

  &__circle {
    margin: 0 auto;
    margin-bottom: 20px;
    width: 130px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid #00b2a4;
    border-radius: 50%;
    position: relative;
    z-index: -1;
    & img {
      position: absolute;
    }
    & h3 {
      color: rgba(63, 69, 81, 1);
      line-height: 32px;
      font-size: 32px;
      z-index: 2;
    }
    & span {
      font-size: 13px;
      color: rgba(114, 126, 142, 1);
      z-index: 2;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  &__country {
    flex: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    & span {
      font-size: 13px;
      margin-bottom: 2px;
      max-width: max-content;
      margin: 0 auto;
      position: relative;
      left: -10px;
    }
    & h3 {
      font-size: 13px;
      color: rgba(114, 126, 142, 1);
      max-width: max-content;
      margin: 0 auto;
      position: relative;
      & span {
        position: absolute;
        min-width: 10px;
        min-height: 10px;
        border-radius: 50%;
        left: -13px;
        bottom: 3px;
        display: block;
      }
    }
  }
}
