.contacts-popup {
  position: absolute;
  width: 100%;
  height: 100%;

  &--participants {
    .contacts-popup__main {
      width: 600px;
      min-height: 265px;
      position: absolute;
      top: 160px;
      transform: translate(-50%, 0);
    }
  }

  &__main {
    z-index: 1000;
    position: fixed;
    width: 565px;
    padding: 30px;
    background: #fff;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__title {
    color: #3f4551;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }

  &__buttons {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }

  &__close {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: black;
    opacity: 0.1;
  }
}
