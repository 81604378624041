@import "~bootstrap/scss/bootstrap";
@import "./_imports";
@import "./_normalize";

body {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

a {
  &:focus,
  &:active {
    outline: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

button {
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
  }
}

ul {
  & li {
    list-style: none;
  }
}

input {
  &:focus {
    outline: none;
  }
}

.react-datepicker__current-month {
  text-transform: capitalize;
}

.react-datepicker-popper {
  z-index: 101;
}
