.landing-three-whales {
  background: url("../../../assets/img/three-whales-bg.png") no-repeat center 75%;

  @media (max-width: 800px) {
    background: url("../../../assets/img/three-whales-bg.png") no-repeat 100% 100%;
    padding-bottom: 220px;
  }

  @media (max-width: 800px) {
    padding-top: 72px;
  }
  &__main {
    padding: 0 40px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    max-width: 1025px;
    margin-left: auto;
    margin-right: 30%;
    @media (max-width: 1465px) {
      margin-right: 10%;
    }
    @media (max-width: 1100px) {
      margin-right: auto;
    }
    @media (max-width: 900px) {
      flex-direction: column;
    }
    @media (max-width: 800px) {
      margin-top: 20px;
      padding: 0px 24px;
    }
  }
  &__image {
    margin-right: 18px;
    position: relative;
    @media (max-width: 800px) {
      max-width: 100%;
      margin-right: 0;
      & img {
        width: 100%;
      }
    }
  }

  &__image-btn {
    width: 36%;
    height: 36%;
    position: absolute;
    background-color: transparent;

    &--up {
      top: 15%;
      left: 32%;
    }

    &--left {
      bottom: 10%;
      left: 13%;
    }

    &--right {
      bottom: 10%;
      right: 13%;
    }
  }

  &__text {
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    font-family: "Oswald", sans-serif;
    font-size: 24px;
    line-height: 35px;
    @media (max-width: 900px) {
      justify-content: center;
      margin-top: 30px;
    }
    @media (max-width: 800px) {
      margin-top: 24px;
      font-size: 18px;
      margin-bottom: 8px;
    }
    & span {
      background: #3b99cc;
      width: 10px;
      height: 10px;
      margin-right: 10px;
      border-radius: 50%;
    }
    color: #3b99cc;
    text-transform: uppercase;
  }
  &__post-title {
    font-size: 15px;
    line-height: 24px;
    color: #727e8e;
    & span {
      margin-bottom: 20px;
      display: block;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
