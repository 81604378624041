.participants {
  &-main {
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
  }

  &__ads-widget-wrapper {
    flex: 0 49%;
  }

  &__table-checker {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-table {
    &--cards {
      &.table {
        & tr {
          &:hover {
            cursor: pointer;
            background-color: rgb(233, 233, 233);
          }
        }
      }
    }

    &.table {
      & tbody {
        & tr {
          &:first-child {
            & td {
              border-top: none;
            }
          }
        }
        & td {
          vertical-align: middle;
          &:last-child {
            border: 1px solid #e3e9ee;
          }
        }
      }
    }
  }

  &-main--cards {
    flex-wrap: wrap;
  }

  &-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9ecf5;
    margin-bottom: -2px;
  }

  &-stands {
    flex: 1;
    margin-right: 35px;

    &__item {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 12px 16px;
      border: 1px solid #e9ecf5;
      border-radius: 4px;

      margin-bottom: 10px;

      &.owned {
        background: #f7f8fc;
        border: 1px solid #e9ecf5;
      }

      &.owned .participants-stands__name {
        color: #727e8e;
      }
    }

    &__name {
      color: #202531;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-right: 10px;
    }

    &__info {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & .event-item__places {
        margin-bottom: 0;
      }
    }

    &__button {
      margin-left: 15px;
      cursor: pointer;
    }

    &__owner {
      color: #717e88;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;

      margin-right: 15px;
    }
  }

  &-history {
    flex: 0 43%;
    max-width: 420px;

    &__img {
      margin-bottom: 35px;

      img {
        width: 100%;
        border-radius: 7px;
      }
    }
  }

  &-orders {
    &__title {
      color: #727e8e;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }

    &__item {
      position: relative;
      display: flex;
      justify-content: space-between;

      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e9ecf5;

      &-settings {
        display: flex;
        flex-direction: column;
      }

      &-button {
        text-align: right;
        cursor: pointer;
      }

      &-title {
        color: #3f4551;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      &-subtitle {
        color: #727e8e;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }

      &-date {
        color: #727e8e;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  &__tarifs-header {
    font-size: 18px;
    color: #727e8e;
    margin-bottom: 16px;
    font-weight: 400;
  }

  &__tarifs {
    flex: 1;
    margin-right: 15px;
  }
}

.participants-documents {
  padding-top: 25px;

  &__scan {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.ad-tarifs {
  display: flex;
  flex-wrap: wrap;
  &__tarif {
    margin-right: 24px;
    margin-bottom: 50px;
    flex: 0 0 236px;
  }
  &__tarif-img {
    margin-bottom: 12px;
    max-width: 100%;
  }
  &__name {
    font-size: 13px;
    color: #3f4551;
    margin-bottom: 12px;
  }
  &__price-input {
    border: 1px solid #727e8e;
    border-radius: 4px;
    font-size: 13px;
    padding: 9px 16px;
    width: 100%;
  }
}

.stand-tarifs {
  &__buttons {
    & button {
      margin-bottom: 15px;
      &:first-child {
        margin-right: 24px;
      }
    }
  }

  &__table {
    & thead {
      & tr {
        display: flex;
        & th {
          display: flex;
          align-items: center;
          &:first-child {
            flex: 3;
          }
          &:nth-child(2) {
            flex: 1;
          }
          &:nth-child(3) {
            flex: 2;
          }
        }
      }
    }

    & tbody {
      & tr {
        display: flex;
        & td {
          border-top: none !important;
          display: flex;
          align-items: center;
          & input {
            background: #ffffff;
            border-radius: 4px;
            width: 100%;
            padding: 9px 15px;
            font-size: 13px;
            color: rgba(63, 69, 81, 1);
            border: 1px solid #e9ecf5;
            &:focus {
              border: 1px solid #727e8e;
            }
          }
          &:first-child {
            flex: 3;
          }
          &:nth-child(2) {
            flex: 1;
          }
          &:nth-child(3) {
            flex: 2;
          }
        }
      }
    }
  }
}

.participants-history {
  &__img {
    height: auto;
  }
  &__title {
    margin-bottom: 10px;
    padding-bottom: 0px;
    font-size: 16px;
    color: #727e8e;
    border-bottom: 1px solid #dfdfdf;
  }
  &__wrap-fullscreen {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    padding: 10px;
    background: rgba(233, 233, 233, 0.5);
    cursor: pointer;
  }
  &__iframe {
    width: 100%;
    height: 100%;
  }
  &__wrap-iframe {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__shadow {
    background: #ccc;
    height: 160px;
  }
  &__fullscreen {
    width: 100%;
  }
  &__fullscreen-back {
    width: 100%;
    display: none;
  }
  &__img--full &__fullscreen {
    display: none;
  }
  &__img--full &__wrap-fullscreen {
    top: 10px;
    right: 10px;
  }
  &__img--full &__fullscreen-back {
    display: block;
  }
  &__img--full &__wrap-iframe {
    width: 52%;
    position: fixed;
    left: 26%;
    height: 400px;
    top: 20%;
    z-index: 9999;
  }
  &__img--full &__iframe {
    border: 10px solid rgba(39, 39, 39, 0.5);
  }
}
