.analytics-attendance {
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  margin-bottom: 16px;
  &__header {
    display: flex;
    background: #f7f8fc;
    padding: 11px 15px;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    color: rgba(32, 37, 49, 1);
    font-weight: 400;
    margin-right: 10px;
    font-size: 18px;
  }
  &__date-btns {
    display: flex;
  }
  &__date-btn {
    margin-right: 8px;
    padding: 3px 12px;
    background: #e9ecf5;

    font-size: 13px;
    color: rgba(114, 126, 142, 1);
    border-radius: 15px;
    &:last-child {
      margin-right: 0;
    }
    &--active {
      background-color: #fff;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    padding: 25px 15px 15px 15px;
    & .recharts-default-legend {
      // margin-top: 16px !important;
    }

    & .recharts-cartesian-axis-tick {
      & line {
        display: none;
      }
    }
  }
  &__graphic {
    margin: 25px 13px;
    min-height: 261px;
    flex: 1;
    max-width: 100%;
  }
  &__footer {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  &__visitors {
    margin-right: 50px;
    color: rgba(114, 126, 142, 1);
    font-size: 13px;
    position: relative;

    &:before {
      background-color: #c90113;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      left: -18px;
      bottom: 4px;
      display: block;
      content: "";
    }
  }
  &__views {
    color: rgba(114, 126, 142, 1);
    font-size: 13px;
    position: relative;
    &:before {
      background-color: #727e8e;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      left: -18px;
      bottom: 4px;
      display: block;
      content: "";
    }
  }
}
