.example-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.default-legend {
  display: flex;
  align-items: center;
  gap: 4px;

  &_text {
    color: #3F4551;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
}

.indicator {
  width: 8px;
  height: 8px;
  display: inline-block;

  &_sold {
    border: 3px solid red;
  }

  &_free {
    border: 3px solid lime;
  }
}
