.landing-intro {
  position: relative;
  min-height: 100vh;

  @media (max-width: 800px) {
    min-height: auto;
    height: 300px;
    margin-bottom: 250px;
    background: url("../../../assets/img/intro-bg.png") no-repeat center center;
    background-size: cover;
  }

  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & video {
      height: 100%;
    }
  }

  &__video-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #f7f8fc;
    overflow: hidden;
    opacity: 0.5;
  }

  &__container {
    max-width: 1368px;
    margin: 0 auto;
    padding: 34px 239px 34px 125px;
    z-index: 2;
    position: relative;
    padding-top: 0;
    @media (max-width: 1150px) {
      padding: 34px 100px;
    }
    @media (max-width: 900px) {
      padding: 34px;
      padding-top: 17px;
    }
    @media (max-width: 800px) {
      padding: 10px;
    }
  }
  &__header {
    margin-bottom: 45px;
    z-index: 2;
    position: relative;
    padding: 24px;

    @media (max-width: 800px) {
      display: none;
    }
  }
  &__header-bg {
    background-color: #727e8e;
    opacity: 0.8;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &__header-wrapper {
    max-width: 1368px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    z-index: 3;
    position: relative;
  }

  &__open-burger {
    @media (min-width: 801px) {
      display: none;
    }
  }
  &__burger {
    @media (min-width: 801px) {
      display: none;
    }
    & .landing-intro {
      &__join {
        color: #202531;
      }
    }
    position: fixed;
    left: 0;
    top: 0;
    width: 230px;
    height: 100vh;
    background-color: #fff;
    padding: 10px;
    z-index: 20;
  }
  &__close-burger {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    & img {
      width: 24px;
      height: 24px;
    }
  }
  &__burger-menu {
    display: flex;
    flex-direction: column;
    & span {
      & a {
        cursor: pointer;
        color: #202531;
        font-size: 20px;
        margin-right: 50px;
        margin-bottom: 10px;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }

  &__nav {
    display: flex;
    & span {
      color: #fff;

      & a {
        cursor: pointer;
        font-size: 16px;
        margin-right: 50px;
        @media (max-width: 950px) {
          margin-right: 40px;
        }
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
  &__join {
    color: #fff;
    @media (max-width: 900px) {
      margin-right: 60px;
    }
    @media (max-width: 800px) {
      margin-right: 0;
      margin-top: 40px;
      font-size: 20px;
    }
    &:hover {
      color: #fff;
      text-decoration: underline !important;
    }
  }
  &__main {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  &__circle {
    border-radius: 50%;
    width: 584px;
    height: 584px;
    position: relative;
    right: -200px;
    @media (max-width: 900px) {
      width: 358px;
      height: 358px;
      right: -30px;
    }
    @media (max-width: 800px) {
      right: 50%;
      transform: translate(50%);
      top: 165px;
      width: 308px;
      height: 308px;
    }
  }
  &__circle-bg {
    position: absolute;
    border-radius: 50%;
    background: #ffffff;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  &__circle-main {
    position: relative;
    z-index: 10;
    padding: 76px 137px 103px 137px;
    height: 100%;
    @media (max-width: 900px) {
      padding: 30px 50px 34px 50px;
    }
    @media (max-width: 800px) {
      padding: 30px 25px 17px 25px;
    }
  }
  &__circle-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    @media (max-width: 900px) {
      margin-bottom: 40px;
    }
    & img {
      height: 60px;
      width: 60px;
      margin-right: 16px;
      @media (max-width: 900px) {
        width: 40px;
        height: 40px;
      }
    }
    & span {
      color: #202531;
      font-size: 48px;
      font-family: "Oswald", sans-serif;
      text-transform: uppercase;
      @media (max-width: 900px) {
        font-size: 26px;
      }
    }
  }
  &__title {
    font-family: "Oswald", sans-serif;
    font-size: 42px;
    line-height: 62px;
    text-align: center;
    text-transform: uppercase;
    color: #3f4551;
    margin-bottom: 8px;
    @media (max-width: 900px) {
      font-size: 24px;
      margin-bottom: 4px;
      line-height: 35px;
    }
  }
  &__post-title {
    font-size: 16px;
    line-height: 19px;
    color: #727e8e;
    margin-bottom: 60px;
    text-align: center;
    @media (max-width: 900px) {
      font-size: 14px;
      margin-bottom: 43px;
    }
    @media (max-width: 800px) {
      margin-bottom: 20px;
    }
  }
  &__registration-btn {
    display: flex;
    justify-content: center;
    & a {
      padding: 12px 40px;
      font-weight: 500;
      margin: 0 auto;
      position: relative;
      font-size: 18px;
      @media (max-width: 900px) {
        padding: 6px 24px;
        font-size: 16px;
      }
      @media (max-width: 800px) {
        font-size: 14px;
      }
    }
  }
}
