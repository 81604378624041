.landing-digital-abilities {
  padding-top: 130px;
  @media (max-width: 800px) {
    padding-top: 70px;
  }
  &__main {
    max-width: 1300px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    margin-top: 48px;
    grid-gap: 30px;
    padding: 0px 30px;
    margin-bottom: 82px;
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      justify-items: center;
      margin-bottom: 50px;
    }
    @media (max-width: 800px) {
      margin-top: 32px;
      padding: 0px 12px;
      grid-gap: 8px;
      margin-bottom: 30px;
    }
  }
  &__item {
    background: #ffffff;
    border: 1px solid #e9ecf5;
    border-radius: 4px;
    padding: 24px 30px;
    padding-bottom: 32px;
    @media (max-width: 900px) {
      max-width: 450px;
    }
  }
  &__item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & span {
      font-family: "Oswald", sans-serif;
      font-size: 24px;
      color: #3b99cc;
      @media (max-width: 800px) {
        font-size: 18px;
      }
    }
    & img {
      @media (max-width: 800px) {
        width: 44px;
      }
    }
  }
  &__text {
    font-size: 15px;
    color: #727e8e;
    margin-top: 15px;
    @media (max-width: 800px) {
      font-size: 14px;
    }
  }
}
