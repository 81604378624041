.registration-continue {
  position: relative;
  min-height: 100vh;
  background: #f7f8fc;

  display: flex;
  justify-content: center;
  align-items: center;

  &__main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-right: 270px;
    padding-left: 270px;
  }

  &__item {
    position: relative;
    background: #ffffff;
    border: 1px solid #e9ecf5;
    width: 25%;
    height: 200px;
    margin-right: 45px;

    cursor: pointer;

    &:last-child {
      margin-right: 0px;
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      margin-bottom: 20px;

      transition: all 1s ease-out;
    }

    &:hover i svg path {
      fill: #3b99cc;
    }

    &:hover .registration-continue__title {
      color: #3b99cc;
    }
  }

  &__title {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    color: #3f4551;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    text-align: center;
  }
}

@media (max-width: 1300px) {
  .registration-continue {
    &__main {
      padding: 0;
    }
  }
}

@media (max-width: 575px) {
  .registration-continue {
    min-height: 100vh;
    padding-left: 15px;
    padding-right: 15px;

    &__main {
      padding-top: 130px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &__item {
      margin-top: 10px;
      margin-right: 0px;
      width: 100%;
    }
  }
}
