.program {
  &__main {
    max-width: 1000px;
  }

  &__buttons {
    margin-top: 20px;
  }

  &__nav {
    margin-bottom: 25px;
  }

  &__review {
    max-width: 500px;
  }
}

.program-participant {
  &__nav {
    display: flex;
    border-bottom: 1px solid #e9ecf5;
  }

  &__nav-item {
    flex: 0 1 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: rgba(114, 126, 142, 1);
    padding-bottom: 6px;
    cursor: pointer;
    & span {
      width: 32px;
      height: 22px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      margin-left: 6px;
      border: 1px solid #e9ecf5;
      color: #727e8e;
    }
    &--selected {
      color: rgba(63, 69, 81, 1);
      border-bottom: 1px solid rgba(63, 69, 81, 1);
      & span {
        background: #e9ecf5;
      }
    }
  }

  &__cards {
    margin-top: 20px;
    max-width: 600px;
  }
}
