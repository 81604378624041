.registration-confirm {
  position: relative;
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;

  &__content {
    position: relative;
    padding-top: 90px;
    width: 50%;
    background: #f7f8fc;
    padding-right: 100px;
    padding-left: 100px;
    text-align: center;
  }

  &__title {
    color: #3f4551;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px;

    text-align: center;
  }

  &__main {
    padding: 100px 85px 40px 75px;
  }

  &__icon {
    margin: 0 auto;

    margin-bottom: 20px;
  }

  &__notification {
    color: #3f4551;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    margin-bottom: 10px;
  }

  &__email {
    color: #3b99cc;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;

    text-decoration: underline;
    margin-bottom: 20px;
  }

  &__text {
    color: #727e8e;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 200px;
  }

  &__promt {
    color: #727e8e;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    margin-bottom: 10px;
  }

  &__again {
    color: #3f4551;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
  }
}

@media (max-width: 1400px) {
  .registration-confirm__main {
    padding: 50px 40px 20px 30px;
  }

  .registration-confirm__text {
    margin-bottom: 30px;
  }
}

@media (max-width: 1000px) {
  .registration-confirm__content {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .registration-confirm__content {
    padding: 0;
    padding-top: 50px;
  }

  .registration-confirm__text {
    margin-bottom: 30px;
  }

  .registration-confirm__main {
    padding: 25px 25px 20px 5px;
  }
}

@media (max-width: 575px) {
  .registration-confirm__content {
    padding-top: 100px;
  }
  .registration-confirm__main {
    padding-right: 0px;
  }
}

@media (max-height: 500px) {
  .registration-confirm__content {
    width: 100%;
    padding: 0;
    padding-top: 50px;
  }

  .registration-confirm__text {
    margin-bottom: 30px;
  }

  .registration-confirm__main {
    padding: 25px 25px 20px 5px;
  }
}
