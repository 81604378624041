.notification-list {
  position: relative;
  z-index: 9999;

  &__main {
    max-height: 300px;
    overflow: auto;
  }

  &__button {
    background-color: transparent;
    position: relative;
    &--with-notification {
      &::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: rgba(201, 1, 19, 1);
        display: block;
        position: absolute;
        right: 0;
        top: 3px;
        border-radius: 50%;
      }
    }
  }

  &__notifications {
    border-radius: 4px;
    //padding: 16px 6px;
    position: absolute;
    top: 42px;
    width: 324px;
    right: 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    @media (max-width: 822px) {
      left: 0;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: #f7f8fc;
      transform: matrix(0.71, -0.94, 0.53, 0.71, 0, 0);
      top: -5px;
      right: 14px;
    }
  }

  &__clear-btn {
    color: #c90113;
    padding: 3px 12px;
    border: 1px solid #c90113;
    border-radius: 15px;
    background-color: transparent;
    font-size: 13px;
    display: block;
    margin: 0 auto;

    margin-bottom: 16px;
    &:hover {
      text-decoration: underline;
    }
  }

  &__notification {
    border-top: 1px solid #e9ecf5;
    padding: 9px 0px;
    display: flex;
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__time {
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
    flex: 0 0 20px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
  }

  &__text {
    flex: 1;
    font-size: 13px;
    color: rgba(32, 37, 49, 1);
  }

  &__empty {
    color: rgba(114, 126, 142, 1);
    padding: 15px 25px;
    text-align: center;
    font-size: 13px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    background: #f7f8fc;
    padding: 16px 12px;

    &-title {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #202531;

      span {
        background: #c90113;
        width: 17px;
        height: 17px;
        padding: 2px 5px;
        border-radius: 50%;
        text-align: center;

        font-weight: 500;
        font-size: 11px;
        line-height: 20px;
        color: #ffffff;
      }
    }

    &-clear {
      font-weight: 500;
      font-size: 11px;
      line-height: 20px;
      color: #3b99cc;

      cursor: pointer;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #e9ecf5;

    &-close {
      display: flex;
      img {
        vertical-align: top;
        height: 16px;
        cursor: pointer;
      }
      span {
        margin-top: 5px;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #c90113;
        margin-right: 10px;
      }
    }
  }

  &__item--clear {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #727e8e;
    text-align: center;

    p {
      text-align: center;
    }
  }

  &__name {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #202531;
    margin-bottom: 5px;
  }

  &__time {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #727e8e;
  }
}
