.avatar {
  padding: 116px;
  width: 45%;
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &--from-server {
    .avatar__content {
      &:hover {
        .avatar__image {
          z-index: 70;
        }
        .avatar__btn {
          z-index: 86;
        }
      }
    }
  }

  &__crop {
    & div:first-child {
      height: 100%;
      margin: 0 auto;
    }
  }

  &-upload {
    padding-left: 0px;
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    span {
      color: #3b99cc;
    }
  }

  &__delete {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: transparent;
    z-index: 88;
    padding: 5px 10px;
    border-radius: 3px;
    &:hover {
      background-color: rgb(238, 238, 238);
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__wrap-image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    left: 50%;
    transform: translate(-50%);
    top: 0;
    z-index: 66;
  }

  &__image {
    height: 100%;
    width: 100%;
  }

  &__btn {
    z-index: 1;
    position: relative;
    background: rgba(249, 249, 249, 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__field {
    position: relative;
    margin-top: 5px;
    background: #f7f8fc;
    border: 2px dashed rgba(59, 153, 204, 1);
    border-radius: 5px;
    margin-bottom: 8px;
    z-index: 0;
    height: 250px;
    width: 250px;
    max-width: 100%;

    &.grag {
      background: darken(#f7f8fc, 10%);
    }
  }

  &__plus {
    margin: 0 auto;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    padding-top: 5px;

    span {
      color: #3b99cc;
    }
  }

  &__text--mod {
    position: relative;
    display: none;
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 5px;

    span {
      color: #3b99cc;
    }
  }

  &__notation {
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.avatar__input--profile {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
}

.avatar--mod {
  padding: 0;
  padding-bottom: 5px;
}

@media (max-width: 900px) {
  .avatar {
    padding: 130px 25px 0px 100px;
  }
}

@media (max-width: 800px) {
  .avatar {
    display: none;
  }

  .avatar__text {
    color: #727e8e;
  }

  .avatar--mod {
    display: block;
  }

  .avatar__text--mod {
    display: block;
  }
}

@media (max-height: 900px) {
  .avatar {
    padding-bottom: 0px;
  }
}
