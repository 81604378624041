.moderation-table{
  cursor: auto;
  &__thead{
    
  }
  &__th{

  }
  &__tr{

  }
  &__target-link{
    font-size: 0.75rem;
    margin: 0 10px;
  }
  &__td{
    &:hover .target-link{
      text-decoration: underline;
    }

  }
  &__logo{

  }
  &__title{
    font-size: 0.95rem;
  }
  &__wrap-play-icon{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    align-items: center;
    justify-content: center;
    background: rgb(205, 205, 205);
    transition: all 0.5s;
    cursor: pointer;
  }
  &__play-icon{
    width: 50px;
    opacity: 0.8;
  }
  &__preview{
    position: relative;
    display: inline-block;
    &:hover .wrap-play-icon{
      opacity: 0.7;
    }
  }
}
.moderation{
  &__actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    background: #f7f8fc;
  }
  &__btn-group{

  }
  &__btn{

  }
  &__textarea{
    width: 100% !important;
    border: 1px solid rgb(225, 225, 225);
    padding: 10px;
    border-radius: 5px;
  }
  &__label{
    margin-bottom: 5px;
  }
}
