.button {
  padding: 6px 24px;
  background: #c90113;
  border: 1px solid transparent;
  outline: none;
  border-radius: 50px;

  color: #f2f2f2;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  cursor: pointer;

  transition: all 0.3s ease-out;

  &:hover:not([disabled]) {
    background: #8e2f39;
    color: #fff !important;
  }
  &.disabled {
    color: #727e8e;
    background: #e9ecf5;

    border-color: #e9ecf5;

    cursor: not-allowed;

    &:hover {
      color: #727e8e;
      background: #e9ecf5;

      border-color: #e9ecf5;
    }
  }
  &__save {
    margin-top: 40px;
  }

  &.create-stand {
    margin: 0 auto;
  }
}

.button--inverse {
  color: #c90113;
  border: 0.5px solid #c90113;
  background: transparent;

  &:hover {
    color: #fff;
    background: #c90113;
    border-color: #c90113;
  }
}

.button--mr10 {
  margin-right: 10px;
}

.demo-event__button-share {
  background: #09aaa4;

  &:hover {
    background: darken(#09aaa4, 10%);
  }
}

.demo-event__button-visit {
  background: #ffffff;
  color: #3b99cc;

  &:hover {
    background: darken(#ffffff, 15%);
  }
}

@media (max-width: 575px) {
  .main__documents__button {
    position: absolute;
    bottom: 80px;
  }
}

@media (max-height: 700px) {
  .button__save {
    margin-top: 0;
  }
}
