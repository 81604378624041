.checker {
  position: relative;
  width: 48px;
  height: 24px;

  -webkit-appearance: none;
  outline: none;
  border-radius: 100px;
  border: 1px solid #727e8e;
  transition: 0.5s;

  cursor: pointer;

  &:checked {
    background: #3b99cc;
    border-color: #3b99cc;
  }

  &:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: #727e8e;
    top: 14%;
    left: 3px;
    border-radius: 20px;
    transition: 0.5s;
    transform: scale(1.1);
  }

  &:checked:before {
    left: 27px;
    background: #ffffff;
  }
}
