// .page-wrapper {
//  height: 100vh;
//  overflow: hidden;
// }

.app {
  position: relative;
  min-height: 100vh;
}

.page-wrapper-documents {
  height: 100vh;
  overflow: hidden;
}

.page-wrapper-password {
  height: 100vh;
  overflow: hidden;
}

.page__main {
  min-height: calc(100vh - 150px);
  display: flex;
  padding-left: 64px;
}

.personal {
  display: flex;
  justify-content: space-between;
  width: 73%;
  flex:1;
}

.main {
  width: 55%;
  padding: 28px 32px;
  padding-bottom: 20px;

  &__reset-icon {
    position: absolute;
    top: 46%;
    right: 2%;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  &-documents {
    width: 100%;
  }

  &__documents {
    display: flex;
    justify-content: space-between;

    &__button {
      padding-right: 10px;
    }
  }

  &__title {
    color: #202531;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    margin-bottom: 15px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #727e8e;
  }

  &__form {
    padding-top: 25px;
    padding-bottom: 20px;
  }
  &__form--registration-event {
    width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  .app {
    position: relative;
  }

  .personal {
    flex-wrap: wrap;
  }

  .main {
    width: 100%;
    padding-bottom: 20px;

    &-password {
      padding-bottom: 100px;
    }
  }
  .avatar {
    position: relative;
    width: 100%;
    padding: 20px;

    margin-bottom: 50px;
    top: -175px;

    &-upload {
      padding-left: 30px;
    }

    &__field {
      background: transparent;
      border: none;
      padding: 0;
      padding-left: 10px;
    }

    &__plus {
      display: none;
    }

    &__text {
      color: transparent;
      text-align: left;
      padding-top: 5px;

      span {
        color: rgba(59, 153, 204, 1);
      }
    }

    &__notation {
      position: relative;
      top: -20px;
      padding-left: 10px;
    }
  }
}

@media (max-width: 900px) {
  .personal {
    width: 100%;
  }
  .page-wrapper-documents {
    height: 100%;
  }
  .page-wrapper-password {
    height: 100%;
  }
}

@media (max-height: 900px) {
  .input--profile {
    padding-bottom: 10px;
  }
}

@media (max-height: 700px) {
  .page-wrapper {
    height: 100%;
  }
}

@media (max-height: 600px) {
  .main {
    padding-bottom: 10px;
    padding-top: 10px;

    &__title {
      margin-bottom: 5px;
    }
    &__form {
      padding-top: 10px;
    }
  }
  .main-document {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-height: 500px) {
  .page-wrapper-documents {
    height: 100%;
  }
}

@media (max-height: 400px) {
  .page-wrapper-password {
    height: 100%;
  }
}
