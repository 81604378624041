.audio_player {
  width: 100px;
  padding: 5px;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  gap: 5px;
  z-index: 999;

  position: absolute;
  bottom: 0;
  left: 0;
}

.button {
  background-color: transparent;
  border: 1px solid black;
  padding: 5px 7px;
}
