.registration-form__footer {
  position: absolute;
  bottom: 0.5%;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 150px;
  padding-bottom: 42px;
}
.registration-form__text {
  text-align: center;
  color: #727e8e;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  margin-bottom: 15px;
}
.registration-form__social {
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    width: 24px;
    height: 24px;
    margin-right: 20px;

    cursor: pointer;
  }
}

@media (max-width: 1400px) {
  .registration-form__footer {
    bottom: 2%;
  }
}

@media (max-height: 400px) {
  .registration-form__footer {
    bottom: 0.5%;
  }
  .registration-form__text {
    margin-bottom: 7px;
  }
}
