.popup-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 500;

  &__bg {
    height: 100%;
    width: 100%;
    background: #202531;
    opacity: 0.5;
    position: absolute;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 32px;
    border-radius: 4px;
    overflow: auto;
    max-height: 100vh;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    color: #3f4551;
  }

  &__close-btn {
    background-color: transparent;
    & img {
      width: 12px;
      height: 12px;
    }
  }
}
