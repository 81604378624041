.example {
  &.example--double {
    width: 100%;
  }

  &.create-stand-example {
    margin-top: 15px;

    & .example {
      &__text {
        display: none;
      }

      &__main {
        min-height: 500px;
        height: 70vh;
      }

      &__places {
        height: 100%;
      }

      &__loader {
        height: 100%;
      }
    }

    & .example-place {
      height: 100%;
      &__shadow {
        height: 100%;
      }
      &__iframe-placeholder {
        max-height: 100%;
        width: auto;
        margin: 0 auto;
        display: block;
      }
    }
  }

  &__text {
    color: #727e8e;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    margin-bottom: 10px;
  }
  &__main {
    background: #e9ecf5;
    border-radius: 6px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }

  &__loader {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  &__loader-image {
    width: 75px;
    height: 75px;
  }

  &__places {
    &--loading {
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 1px;
      opacity: 0;
      z-index: -1;
    }
  }

  &-nav {
    display: flex;
    padding: 7px 0px 5px 10px;
    margin-bottom: 10px;
    &__item {
      width: 8px;
      height: 8px;
      background: #727e8e;
      border-radius: 50%;

      margin-right: 6px;

      cursor: pointer;
    }
  }
}

.example-place {
  height: 200px;
  padding: 0 10px;
  &--hidden {
    pointer-events: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
  &__title {
    margin-bottom: 5px;
    padding-bottom: 0px;
    font-size: 16px;
    color: #727e8e;
  }
  &__wrap-fullscreen {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    padding: 10px;
    background: rgba(233, 233, 233, 0.5);
    cursor: pointer;
  }
  &__iframe {
    width: 100%;
    height: 100%;
  }

  &__iframe-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__wrap-iframe {
    background: #ccc;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__shadow {
    background: #ccc;
    height: 160px;
  }

  &__fullscreen {
    width: 100%;
  }

  &__fullscreen-back {
    width: 100%;
    display: none;
  }

  &--full &__fullscreen {
    display: none;
  }

  &--full &__iframe-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    overflow: auto;
  }

  &--full &__wrap-iframe {
    width: 52%;
    margin: 0 auto;
    max-height: 600px;
    margin-top: 10vh;
    height: 85vh;
    margin-bottom: 5vh;

    z-index: 667;
  }

  &--full &__wrap-fullscreen {
    top: 10px;
    right: 10px;
  }

  &--full &__fullscreen-back {
    display: block;
  }

  &--full &__iframe {
    border: 10px solid rgba(39, 39, 39, 0.5);
  }
}

.example--org {
  width: 40%;
}

.example--double {
  .example-place {
    height: 330px;
  }

  .example-place__shadow {
    height: 300px;
  }

  .example-place--full .example-place__wrap-iframe {
    // width: 52%;
    // position: fixed;
    // left: 26%;
    // height: 600px;
    // top: 10%;
    // z-index: 9999;
  }
}
