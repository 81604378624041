.create-stand {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__main {
    display: flex;
    padding-left: 64px;
    flex: 1;
    max-height: calc(100vh - 64px);
    @media (max-width: 900px) {
      padding-left: 0;
      padding-bottom: 64px;
    }
    @media (max-width: 795px) {
      max-height: calc(100vh - 100px);
    }
    @media (max-width: 600px) {
      max-height: calc(100vh - 142px);
    }
  }
  &__sidebar {
    flex: 0 0 298px;
    background: #f7f8fc;
    overflow: auto;

    @media (max-width: 900px) {
      padding-bottom: 90px;
    }
  }

  &__content {
    overflow: auto;
    flex: 1;
  }
}

.create-stand-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__exit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    & span {
      font-weight: 500;
      font-size: 15px;
      color: rgba(114, 126, 142, 1);
    }

    & img {
      margin-right: 7px;
    }
  }

  &__nav {
    padding: 32px 23px;
    padding-bottom: 20px;
  }

  &__nav-icon {
    margin-right: 9px;
  }

  &__nav-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
      & span {
        &::after {
          display: none;
        }
      }
    }

    & span {
      border-radius: 50%;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 27px;
      min-width: 27px;
      margin-right: 8px;
      position: relative;
      background: #ffffff;
      color: rgba(114, 126, 142, 1);
      &::after {
        display: block;
        content: "";
        position: absolute;
        left: 12px;
        top: 30px;
        background-color: #727e8e;
        height: 20px;
        width: 1px;
      }
    }

    & h3 {
      color: rgba(114, 126, 142, 1);
      font-size: 15px;
    }

    &--filling {
      & span {
        background: #ffd9dd;
      }

      & h3 {
        color: rgb(82, 82, 82);
      }

      & .create-stand-sidebar__nav-icon {
        svg,
        path {
          fill: rgb(82, 82, 82);
        }
      }
    }

    &--active {
      & span {
        background: #c90113;
        color: rgba(255, 255, 255, 1);
      }

      & h3 {
        color: rgba(32, 37, 49, 1);
      }

      & .create-stand-sidebar__nav-icon {
        svg,
        path {
          fill: black;
        }
      }
    }

    &--done {
      & span {
        border: 1px solid #727e8e;
        color: rgba(114, 126, 142, 1);
      }
      & h3 {
      }
    }
  }

  &__buttons {
    padding: 22px 30px 12px 30px;
    position: sticky;
    bottom: 0;
    background: #e9ecf5;
  }

  &__step-btns {
    display: flex;
    justify-content: center;
  }

  &__step-btn {
    margin-right: 40px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    & span {
      font-family: "Inter";
      font-weight: 500;
      font-size: 15px;
      color: #c90113;
    }

    & svg {
      margin: 0 5px;
    }

    &--disabled {
      cursor: default;
      & span {
        color: #727e8e;
      }
    }
  }

  &__skip-btn {
    font-size: 15px;
    color: rgba(114, 126, 142, 1);
    font-family: "Inter";
    margin: 0 auto;
    margin-bottom: 20px;
    font-weight: 500;
    display: block;
  }
  &__next-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
    @media (max-width: 1200px) {
      font-size: 13px;
    }
  }
}

.create-stand-content {
  padding: 0px 32px;
  padding-top: 28px;

  &--consctuctor-page {
    display: flex;
    flex-direction: column;
  }

  &__checker {
    display: inline-flex;
    align-items: center;
    width: fit-content;
    & span {
      margin-right: 5px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__hint {
    margin-top: 10px;
    margin-bottom: 29px;
    padding: 10px 13px;
    background-color: #ddeffa;
    font-size: 15px;
    line-height: 18px;
    color: #202531;
    display: inline-flex;
    & img {
      margin-right: 9px;
    }

    &--warning {
      background-color: #fff1bd;
    }
  }

  &__error {
    color: #c90113;
    display: block;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 3px;
    font-weight: 500;
  }

  &__title {
    font-size: 24px;
    color: rgba(32, 37, 49, 1);
    margin-right: 15px;
    @media (max-width: 900px) {
      flex: 100%;
      text-align: center;
      margin-bottom: 15px;
      margin-right: 0;
    }
  }

  &__buttons {
    display: flex;
    @media (max-width: 900px) {
      flex: 100%;
    }
    & button {
    }
  }
  &__settings-btn {
    color: #3b99cc;
    border: 1px solid #3b99cc;
    margin-right: 25px;
  }
  &__save-btn {
    color: rgba(201, 1, 19, 1);
    border: 1px solid #c90113;
  }
  &__posttitle {
    margin-top: 8px;
    color: rgba(114, 126, 142, 1);
    font-size: 18px;
    display: flex;
    align-items: center;
    & span {
      margin-right: 10px;
    }
    &--second {
      font-size: 13px;
    }
    &--mt {
      margin-top: 40px;
      margin-bottom: 13px;
    }
  }

  &__main {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    &--templates {
      @media (max-width: 1400px) {
        flex-wrap: wrap;
      }
    }

    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }
  &__form {
    flex: 0 500px;
    margin-right: 111px;
    @media (max-width: 1200px) {
      margin-right: 0;
      flex: 100%;
    }
  }
  &__input-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    & label {
      font-size: 13px;
      display: flex;
      align-items: center;
      margin-right: 7px;
      display: block;
      margin-bottom: 5px;
      & img {
        width: 15px;
        height: 15px;
      }
    }
    & input,
    select,
    textarea {
      padding: 12px 14px;
      border: 1px solid #e9ecf5;
      border-radius: 4px;
      font-size: 13px;
      background: #fff;
      padding-right: 20px;
      &:focus {
        border: 1px solid #3b99cc;
      }
    }
    margin-bottom: 23px;

    & textarea {
      height: 130px;
      resize: none;
    }
  }

  &__sidebar {
    flex: 0 329px;
    @media (max-width: 1200px) {
      flex: 100%;
    }
  }

  &__avatar {
    margin-top: 25px;
    max-width: 329px;
    max-height: 250px;
    @media (max-width: 1200px) {
      margin: 0 auto;
      margin-top: 15px;
      margin-bottom: 15px;
      flex: 100%;
    }
  }

  &__avatar-delete {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
    padding: 5px 8px;
    border-radius: 3px;

    &:hover {
      background-color: rgb(238, 238, 238);
    }
  }

  &__avatar-input {
    margin-bottom: 8px;
    position: relative;
    background: #f7f8fc;
    border: 1px dashed #3b99cc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 180px;
    z-index: 5;
  }
  &__avatar-input--red {
    background: rgba(255, 0, 0, 0.2);
    border: 1px dashed rgba(255, 0, 0, 1);
  }
  &__avatar-file {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 99;
  }
  &__add-avatar-add-img {
    background: #ffffff;
    width: 22px;
    height: 22px;
    margin: 0 auto;
    display: block;
    margin-bottom: 17px;
    border-radius: 50%;
    padding: 3px;
  }
  &__avatar-image {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    z-index: 66;
    position: relative;
  }
  &__add-avatar-text {
    font-size: 13px;
    text-align: center;
    & h3 {
      color: #3b99cc;
      font-size: 13px;
      margin-bottom: 3px;
    }
    & span {
      color: #727e8e;
    }
  }
  &__avatar-posttitle {
    color: #727e8e;
    font-size: 13px;
  }
  &__avatar-posttitle--strong {
    color: rgba(32, 37, 49, 1);
    font-weight: bold;
  }

  &__side-info {
    flex: 0 38%;
    margin-left: 2%;
    &--templates {
      @media (max-width: 1400px) {
        order: 1;
        margin-left: 0;
        margin-bottom: 40px;
        flex: 100%;
      }
    }
  }

  &__templates {
    margin-right: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 17px;

    @media (max-width: 1700px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 1400px) {
      order: 2;
      flex: 100%;
      grid-template-columns: repeat(3, 1fr);
      margin-right: 0;
    }

    @media (max-width: 1080px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__template {
    flex: 0 266px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    max-width: 300px;

    &--reserved {
      cursor: not-allowed;
    }

    & img {
      display: block;
      border-radius: 5px;
      max-width: 100%;
      border: 2px solid transparent;
      padding: 2px;
    }

    &--selected {
      position: relative;
      &::after {
        position: absolute;
        display: block;
        content: "";
        background-image: url("../../assets/img/SelectedArrow.svg");
        background-color: #fff;
        border-radius: 50%;
        left: 8px;
        bottom: 44px;
        width: 18px;
        height: 18px;
      }

      & img {
        border-color: #3b99cc;
      }
    }
    & img {
      margin-bottom: 12px;
    }
    & span {
      font-size: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__template-count {
    color: #27ae60;
    border: 0.5px solid rgba(39, 174, 96, 0.7);
    border-radius: 15px;
    padding: 4px 12px;
    font-size: 16px;
    line-height: 16px;
  }

  &__main-hall {
    position: relative;
    flex: 0 363px;
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
    @media (max-width: 700px) {
      flex: 1;
    }
  }
  &__main-hall-img {
    max-width: 400px;
    height: 100%;
    width: 100%;
  }
  &__resize-img {
    position: absolute;
    bottom: 10px;
    left: 10px;
    border-radius: 50%;
    padding: 3px;
    background-color: #fff;
  }
  &__checkboxes {
    display: flex;
  }
  &__checkbox {
    align-items: center;
    display: flex;
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
    & input {
      display: block;
      margin-right: 8px;
    }
    & label {
      margin-bottom: 0;
    }
  }
  &__price-table {
    width: 100%;
    margin-bottom: 20px;
  }
  &__price-header {
    padding-left: 0 !important;
    & td {
      font-size: 16px;
      &:first-child {
        font-size: 18px;
      }
    }
  }
  &__price-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 32px;

    &:first-child {
      & td {
        margin-right: 5vw;
      }
    }

    &:nth-child(2n) {
      background: #f7f8fc;
    }

    &--nested {
      background: #fff !important;
      padding-left: 60px;
      & td {
        margin-right: calc(5vw - 53px);
      }
    }

    & td {
      flex: 0 33%;
      max-width: 171px;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      &:first-child {
        text-align: start;
        justify-content: flex-start;
        min-width: 419px;
        color: #727e8e;
        flex: 1 419px;
        @media (max-width: 1200px) {
          flex: 1 200px;
          min-width: 100px;
        }
      }
      @media (max-width: 900px) {
        flex: 30%;
        margin-right: 10px;
      }
    }
  }

  &__price-cell {
    margin-right: calc(5vw - 80px);
    &:first-child {
      margin: 0;
    }
    &:last-child {
      margin-right: 5vw;
    }
    & span {
      display: block;
    }
    &--bold {
      font-weight: 500;
      font-size: 16px !important;
    }
  }

  &__price-footer {
    & td {
      font-weight: 500;
      font-size: 16px;
    }
  }

  &__input-promt {
    position: absolute;
    top: 0;
    left: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #fcfdff;

    padding: 4px 8px;
    background: #282b31;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-left: 8px;
    transition: all 0.1s linear;
    visibility: hidden;

    &::before {
      content: "";
      position: absolute;
      left: -12px;
      top: 25%;
      border: 6px solid transparent;
      border-top: 6px solid #282b31;
      transform: rotate(90deg);
    }

    &.description {
      width: 130px;
    }

    &.type {
      width: 290px;
    }

    &.inn {
      width: 350px;
      top: -60%;
    }
    &.registrationNumber {
      width: 350px;
      top: -40%;
    }

    &.bic {
      width: 250px;
    }

    &.correspondingNumber {
      width: 300px;
    }
    &.branch {
      width: 230px;
    }
    &.vat {
      width: 90px;
    }
  }

  &__input-wrapper {
    margin-top: 5px;
  }

  &__input-alert {
    display: flex;
    align-items: baseline;
  }

  &__input-icon {
    position: relative;
    width: 18px;
    cursor: pointer;

    &:hover .create-stand-content__input-promt {
      visibility: visible;
      transition: all 0.1s linear;
    }
  }

  &__preview {
  }

  &__stand-buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__stand-button {
    position: relative;
    align-items: center;
    background: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    display: block;
    cursor: pointer;
    width: 12.5%;
    height: 8%;
    &--active {
      background: rgb(200, 253, 255);
    }
    span {
      position: relative;
      color: #fff;
      font-weight: 500;
      font-size: 8px;
      line-height: 24px;
      background: #3b99cc;
      padding: 1px 2px;
      border-radius: 100%;
    }
    &--first {
      left: 7%;
      top: 31%;
      transform: rotate(4deg);
      width: 13%;
      height: 10%;

      span {
        top: -24%;
      }
    }
    &--second {
      left: 24%;
      top: 11%;
      transform: rotate(4deg);
      width: 11%;
      height: 8%;

      span {
        top: -40%;
      }
    }
    &--third {
      left: 65%;
      top: 3%;
      transform: rotate(-4deg);
      width: 12%;
      height: 7%;

      span {
        top: -52%;
      }
    }
    &--fourth {
      left: 81%;
      top: 7%;
      transform: rotate(-2deg);
      width: 12.5%;
      height: 8%;

      span {
        top: -42%;
      }
    }
    &--fifth {
      left: 20%;
      top: 11%;
      width: 12.5%;
      height: 8%;
    }
    &--sixth {
      left: 40%;
      top: 1%;
      width: 18.5%;
      height: 11%;
    }
    &--seventh {
      left: 67%;
      top: -10%;
      width: 14.5%;
      height: 11%;
    }
    &--eighth {
      left: 85%;
      top: -5%;
      width: 14.5%;
      height: 11%;
    }
    &--ninth {
      left: 1%;
      top: -15%;
      width: 14.5%;
      height: 11%;
    }
    &--tenth {
      left: 23%;
      top: -3%;
      width: 22.5%;
      height: 13%;
    }
    &--eleventh {
      left: 51%;
      top: -17%;
      width: 22.5%;
      height: 13%;
    }
  }
}
