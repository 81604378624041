.layout {
  position: relative;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__main {
    min-height: calc(100vh - 150px);
    display: flex;
    padding-left: 64px;
    flex: 1;
    @media (max-width: 900px) {
      padding-left: 0;
    }
  }
}
