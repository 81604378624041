.event-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 101;

  &--notification {
    z-index: 88;
    position: fixed;
  }

  &__cancel-btn {
    margin-right: 15px;
  }

  &__main {
    z-index: 1000;
    position: fixed;
    width: 458px;
    padding: 30px;
    background: #fff;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay {
    position: absolute;
    width: 100vw;
    min-height: 100vh;
    z-index: 100;
    background-color: black;
    opacity: 0.1;
    height: 100%;
  }

  &__overlay-sb {
    position: fixed;
  }

  &__title {
    color: #3f4551;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    margin-bottom: 15px;
  }

  &__close {
    position: absolute;
    right: 5%;
    top: 14%;

    cursor: pointer;
  }

  &__text {
    color: #3f4551;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    margin-bottom: 25px;

    &.link {
      display: block;
      span {
        text-decoration: underline;
      }
    }
  }

  &__buttons {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;

    &.webinar {
      justify-content: center;
      float: none;
    }
  }
  &__solo-btn {
    margin: 0 auto;
  }
  &__button {
    margin-right: 7px;
    & img {
      margin-right: 5px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__buttons--close {
    justify-content: center;
  }
  &__link {
    padding: 5px 15px;
    cursor: pointer;
    margin: 0px;
    margin-right: 15px;
    &:hover {
      color: #fff !important;
    }
    &--cancel {
      border: 0.5px solid #c90113;
      background: transparent;
      color: #c90113;
      display: block;

      &:hover {
        color: #8e2f39;
        background: #fff;
        border-color: #8e2f39;
      }
    }
    &--close {
      min-width: 64px;
    }
  }

  &__link--settings {
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }
}
