.banners {
  padding-top: 15px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  margin-left: 35px;
  width: 280px;
}

.banner {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 4px;
  & img {
    width: 100%;
  }
  &--small {
    height: 143px;
  }
  &--big {
    height: 389px;
  }
}

@media (max-width: 800px) {
  .banners {
    display: none;
  }

  .add-boxes {
    display: none;
  }
}
