.profile-popup {
  flex: none;
  z-index: 999;
  width: 261px;
  padding: 24px 16px 16px 16px;
  background: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 0px 10px 0px #0000001a;
  color: #000;
  cursor: auto;
  @media (max-width: 800px) {
    right: 0;
  }

  &.event {
    background: inherit;
  }

  &__main {
    padding-bottom: 0;
    border-bottom: 1px solid #e9ecf5;
  }

  &__accounts {
    padding: 16px 0;

    border-bottom: 1px solid #e9ecf5;

    margin-bottom: 15px;
  }

  &__edit-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  &__edit-btn {
    font-size: 13px;
    line-height: 16px;
    display: inline-flex;
    padding: 5px 12px;
  }

  &__exit {
    color: #727e8e;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    background-color: transparent;
    text-align: center;
    cursor: pointer;
    margin: 0 auto;
    display: block;

    transition: all 1s ease-out;
    &:hover {
      color: darken(#727e8e, 20%);
      transition: all 1s ease-out;
    }
  }
  .change-role {
    margin-top: 15px;
    text-align: center;
    border-top: 1px solid #ccc;
    padding: 15px 0 10px;
    border-bottom: 1px solid #ccc;
    &__wrap-roles {
      font-style: normal;
    }
    &__roles {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 0;
    }
    &__role {
      border-radius: 5px;
      overflow: hidden;
      transition: all 0.4s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #c9c9c9;
      }
      &.active {
        // background: #c9c9c9;
      }
    }
    &__role-title {
      font-size: 11px;
    }
    &__role-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px;
      width: 100px;
      height: 80px;
    }

    &__title {
      color: #727e8e;
      font-size: 14px;
    }
  }
}

@media (max-width: 575px) {
  .profile-popup {
    top: 120px;
  }
}

.popup-user {
  display: flex;
  cursor: pointer;

  &__avatar {
    position: relative;
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    margin-right: 7px;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 1px solid #e9ecf5;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__info {
    width: 80%;
    margin-bottom: 25px;
  }

  &__name {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #000;
  }

  &__pos {
    color: #727e8e;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
  }

  &__add {
    background-image: url("../../../assets/img/popupUserAdd.svg");
    background-repeat: no-repeat;
    color: #3b99cc;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    padding-left: 20px;
    transition: all 1s ease-out;
    cursor: pointer;

    &:hover {
      color: darken(#3b99cc, 10%);
      transition: all 1s ease-out;
    }
  }

  &__add--web {
    font-size: 16px;
  }

  &__add--product {
    font-size: 16px;
    line-height: 24px;
  }
}
