.notification {
  &-main {
    padding-top: 25px;
  }

  &-item {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;

    border: 1px solid #e9ecf5;
    border-radius: 4px;

    margin-bottom: 8px;

    &__content {
      width: 40%;
    }

    &__input {
      width: 10%;
    }

    &__title {
      color: #202531;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      margin-bottom: 5px;
    }

    &__text {
      color: #727e8e;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }

  &-switch {
    input {
      position: relative;
      width: 48px;
      height: 24px;

      -webkit-appearance: none;
      outline: none;
      border-radius: 100px;
      border: 1px solid #727e8e;
      transition: 0.5s;

      cursor: pointer;
    }

    input:checked {
      background: #3b99cc;
      border-color: #3b99cc;
    }

    input:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background: #727e8e;
      top: 14%;
      left: 3px;
      border-radius: 20px;
      transition: 0.5s;
      transform: scale(1.1);
    }

    input:checked:before {
      left: 27px;
      background: #ffffff;
    }
  }
}

@media (max-width: 1100px) {
  .notification {
    &-main {
      padding-bottom: 50px;
    }
  }
}
