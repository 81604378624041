.landing-platform-abiities {
  margin-top: 100px;
  @media (max-width: 1070px) {
    margin-bottom: 0;
  }
  &__main {
    display: flex;
    max-width: 1320px;
    padding: 10px;
    margin: 0 auto;
    margin-top: 48px;
    position: relative;
    @media (max-width: 1000px) {
      margin-top: 70px;
      margin-bottom: 60px;
    }
    @media (max-width: 800px) {
      margin-top: 32px;
      flex-direction: column;
    }
  }

  &__cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    row-gap: 16px;
    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__card {
  }

  &__side-image {
    position: relative;
    top: -238px;
    width: 794px;
    left: 47%;
    z-index: 4;
    & img {
      position: relative;
      z-index: 6;
      width: 754px;
    }
    @media (max-width: 1000px) {
      width: 560px;
      top: -50px;
      left: 40%;
      & img {
        width: 600px;
      }
    }
    @media (max-width: 800px) {
      left: 30%;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    @media (max-width: 600px) {
      left: 6%;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__image-bg {
    z-index: 5;
    background: #ffffff;
    opacity: 0.7;
    border-radius: 50%;
    width: 852px;
    height: 794px;
    position: absolute;
    left: 0;
    top: 0;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  &__registration {
    position: relative;
    margin-bottom: 100px;
    margin-top: -348px;
    z-index: 5;
    @media (max-width: 1000px) {
      margin-bottom: 0;
      position: relative;
      margin-top: 0;
      bottom: 125px;
    }
    @media (max-width: 800px) {
    }
  }
}

.platform-abiities-card {
  padding: 24px 32px;
  background: #ffffff;

  border: 1px solid #e9ecf5;
  border-radius: 4px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    & h2 {
      text-transform: uppercase;
      font-size: 24px;
      font-family: "Oswald", sans-serif;
      color: #3b99cc;
      font-weight: 400;
    }
    & img {
    }
  }
  &__text {
    font-size: 13px;
    color: #727e8e;
    & span {
      display: block;
    }
  }
}
