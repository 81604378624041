.landing-closest-events {
  padding: 0px 15px;
  padding-top: 20px;

  &__main {
    max-width: 941px;
    margin: 0 auto;
  }

  &__event {
    margin: 0;
    background-color: transparent;
    margin: 30px 0px;
  }

  &__show-more {
    padding: 6px 24px;
    font-weight: 500;
    color: #3b99cc;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid #3b99cc;
    border-radius: 50px;
    display: block;

    &:hover {
      background: #e6f7ff;
      transition: 0.2s;
    }
  }
}
