.dropfield {
  width: 100%;

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--shared {
      min-width: 170px;
    }

    &.big {
      width: auto;
      max-width: 320px;
      height: 100%;
      z-index: 66;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  &__main {
    position: relative;
    margin-top: 5px;
    background: #f7f8fc;
    border: 2px dashed #3b99cc;
    border-radius: 5px;
    margin-bottom: 8px;
    z-index: 0;
    height: 200px;
    cursor: pointer;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &__plus {
    margin: 0 auto;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #fff;
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    padding-top: 5px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__text {
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    padding-top: 5px;

    span {
      color: #3b99cc;
    }
  }
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 99;
  }
  &__notation {
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
}

.dropfield-mini {
  width: 100%;
  height: 166px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #f7f8fc;
  border: 2px dashed #3b99cc;
  overflow: hidden;

  &__image {
    width: 100%;
    height: 100%;
    z-index: 66;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__plus {
    margin: 0 auto;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: #fff;
    margin-bottom: 15px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__text {
    width: 100%;
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;

    span {
      color: #3b99cc;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 99;
  }
}
