.btn {
  font-weight: 500;
  border-radius: 50px;
  padding: 6px 24px;
  text-align: center;
  opacity: 1 !important;
  pointer-events: all !important;
  outline: none !important;
  box-shadow: none !important;

  &:hover,
  &:focus {
    transition: 0.2s;
  }

  &:disabled {
    cursor: default;
  }
}

.red-btn {
  @extend .btn;
  background-color: rgba(201, 1, 19, 1);
  color: rgba(242, 242, 242, 1);
  &:hover,
  &:focus {
    background-color: #8e2f39;
    color: #f2f2f2;
  }

  &:disabled {
    color: #727e8e;
    background: #e9ecf5;
    border-color: #e9ecf5;
  }
}

.white-btn {
  @extend .btn;
  background-color: #ffffff;
  color: #c90113;
  border: 1px solid #c90113;

  &:hover,
  &:focus {
    border: 1px solid #8e2f39;
    color: #8e2f39;
  }

  &:disabled {
    color: #727e8e;
    border-color: #727e8e;
  }
}

.blue-btn {
  @extend .btn;
  border: 1px solid #3b99cc;
  color: #3b99cc;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: #3b99cc;
    background-color: #d8f1ff;
  }

  &:disabled {
    color: #727e8e;
    border-color: #727e8e;
  }

  &--active {
    background: #3b99cc;
    border: 1px solid #fff;
    color: #fff;
    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      background: #3b99cc;
      border: 1px solid #fff;
      color: #fff;
    }

    &:disabled {
      background: #9fddff;
    }
  }
}

.button.white-btn {
  @extend .btn;
  font-weight: 500;
  background-color: #ffffff;
  color: #c90113;
  border-radius: 50px;
  padding: 6px 24px;
  border: 1px solid #c90113;

  &:hover,
  &:focus {
    background-color: #8e2f39;
    border: 1px solid #8e2f39;
    color: #fff;
    transition: 0.2s;
  }

  &:disabled {
    color: #727e8e;
    border-color: #727e8e;
    cursor: not-allowed;
  }
}

.default-input {
  padding: 12px 14px;
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  font-size: 13px;

  &--error {
    border-color: #c90113;
  }

  &--filled {
    border-color: #727e8e;
  }

  &:focus {
    border-color: #3b99cc;
  }
}

.input-error {
  font-size: 11px;
  line-height: 14px;
  margin-top: 3px;
  color: #c90113;
  font-weight: 400;
}
