.registration {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .registration--reset {
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }

  &-form {
    position: relative;
    padding-top: 90px;
    width: 50%;
    background: #f7f8fc;
    padding-right: 100px;
    padding-left: 100px;

    &__loader {
      height: 100%;
      width: auto;
    }

    &__error {
      color: #c90113;
      font-weight: 500;
      font-size: 15px;
    }

    &__finally {
      color: #3f4551;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      text-align: center;
      margin-top: 57px;
      margin-bottom: 70px;
    }

    &__header {
      position: absolute;
      z-index: 999;
      top: 15px;
      right: 51px;
    }

    &__title {
      color: #3f4551;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 15px;

      text-align: center;
    }

    &__agreement {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      width: 80%;

      margin-top: 5px;
      margin-bottom: 20px;

      &-input {
        width: 16px;
        height: 16px;

        margin-top: 2px;

        margin-right: 8px;
        cursor: pointer;
      }

      label {
        padding-top: 2px;
      }
    }
    &__link {
      color: #3b99cc;

      &:hover {
        text-decoration: underline;
      }
    }

    &__submit {
      margin: 0 auto;
      margin-top: 14px;
      display: flex;
      justify-content: center;
      & button {
        height: 38px;
        min-width: 147px;
      }
    }
  }
  &-reset__link {
    margin-top: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 1400px) {
  .registration-form {
    position: relative;
    padding: 30px;
    &__main {
      width: 70%;
      margin: 0 auto;
    }
    &__agreement {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 1000px) {
  .registration {
    &-form {
      width: 100%;
      padding-top: 100px;
    }
  }
}

@media (max-width: 800px) {
  .registration {
    min-height: 100vh;
    &-form {
      padding-top: 80px;

      &__footer.login {
        margin-top: 50px;
      }
    }
  }
  .registration--reset {
    min-height: 100vh;
  }
}

@media (max-height: 700px) {
  .registration-form {
    position: relative;
    padding-top: 50px;

    &__main {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__footer {
      margin-top: 100px;
      padding-bottom: 0px;
    }

    &__agreement {
      width: 100%;
    }
  }
  .input--registration {
    padding-bottom: 10px;
  }
}

@media (max-height: 500px) {
  .registration {
    &-form {
      width: 100%;
      padding-top: 20px;

      &__main {
        padding-top: 20px;
      }

      &__title {
        margin-bottom: 5px;
      }

      &__footer {
        bottom: -10%;

        &.login {
          bottom: 2%;
        }
      }

      &__text {
        margin-bottom: 7px;
      }

      &__footer.login {
        margin-top: 50px;
      }
      &__agreement {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .registration--login .registration-form__main {
    padding-top: 0;
  }
}
@media (max-height: 500px) {
  .registration {
    min-height: 100vh;
    overflow: auto;
  }
  .registration--reset {
    height: 100vh;
    overflow: hidden;
  }
  .registration--login {
    min-height: 100vh;
    overflow: hidden;
  }
}
@media (max-height: 400px) {
  .registration--reset {
    min-height: 100vh;
    overflow: auto;
  }
}
@media (max-width: 575px) {
  .registration {
    min-height: 100vh;
    overflow: auto;
    margin: 0 auto;
    background: #f7f8fc;
    &-form {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 80px;
      width: 100%;

      &__header {
        right: 15px;
      }
    }
  }
}
