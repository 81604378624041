.resume-event {
  display: flex;
  margin-bottom: 32px;
  &__image {
    width: 129px;
    & img {
      width: 100%;
    }
  }

  &__about {
    flex: 1;
    margin-left: 40px;
  }

  &__about-header {
    display: flex;
    justify-content: space-between;
  }

  &__header-left {
    flex: 1;
    margin-right: 40px;
  }

  &__dates {
    font-size: 18px;
    color: #808b99;
    font-weight: 500;
    line-height: 32px;
    font-family: "Roboto", "sans-serif";
    & span {
      color: #3f4551;
    }
  }

  &__title {
    font-weight: 500;
    color: #3f4551;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
    font-family: "Roboto", "sans-serif";
  }

  &__right {
  }

  &__about-text {
    font-family: "Roboto", "sans-serif";
    font-size: 18px;
    color: #727e8e;
    line-height: 24px;
  }
}

.resume-tariffs {
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #202531;
  }
}
