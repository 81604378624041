.favorite-nav {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-left: 25px;
  margin-right: 30px;
  flex-wrap: wrap;
  border-bottom: 1px solid #e9ecf5;

  &--notification {
    margin-left: 0px;
  }

  &__item {
    padding: 5px 9px 5.5px 9px;
    z-index: 1;
    cursor: pointer;
    color: #727e8e;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    border-bottom: 3px solid transparent;

    span {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #727e8e;
      padding: 3px 12px;
      background-color: #fff;
      border: 1px solid #e9ecf5;
      border-radius: 15px;
    }

    &:hover {
      color: #3f4551;
      transition: all 0.2s ease-out;
      span {
        background: #f7f8fc;
      }
    }
  }

  &__item--active {
    border-bottom: 3px solid #3f4551;
    color: #3f4551;
    span {
      background: #e9ecf5;
    }
  }

  & .active &__item {
    border-bottom: 3px solid #3f4551;
    color: #3f4551;
    span {
      background: #e9ecf5;
    }
  }
}

@media (max-width: 575px) {
  .favorite-nav {
    display: flex;
    &__item {
      flex: 100%;
    }
  }
}
