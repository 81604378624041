.sidebar {
  position: fixed;
  z-index: 70;
  width: 64px;
  height: 100vh;
  background: rgba(32, 37, 49, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__top {
  }

  &__bottom {
    margin-bottom: 15px;
  }

  &__info-link {
  }

  &__item {
    position: relative;
    width: 40px;
    height: 40px;
    background: rgba(63, 69, 81, 1);
    border-radius: 100%;
    margin: 16px 12px 0 12px;
    cursor: pointer;
    &.active {
      color: #e9ecf5;
      opacity: 1;
      box-shadow: 0 0 0 2px rgba(32, 37, 49, 1), 0 0 0 4px rgba(142, 47, 57, 1);
      svg {
        & path {
          fill: #fff;
        }
      }
    }

    &:hover {
      background: #727e8e;
      opacity: 1;
      transition: all 0.2s ease-out;
      & svg {
        & path {
          fill: #fff;
        }
      }
    }
    &:hover .sidebar__icon {
      opacity: 1;
    }
    &.sidebar__event {
      background-color: rgb(235, 235, 235);
      & .sidebar__icon-text {
        color: red;
      }
      &:hover {
        background-color: rgb(206, 206, 206);
      }
    }
  }

  &__home-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-text {
      color: #e9ecf5;
      font-size: 11px;
      font-weight: 700;
      line-height: 130%;
      text-transform: uppercase;
    }

    &-plus {
      color: #e9ecf5;
      font-size: 25px;
      font-weight: 700;
      line-height: 130%;
    }
  }
}

@media (max-width: 900px) {
  .sidebar {
    z-index: 666;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 64px;

    &__item {
      margin: 15px;
      margin-top: 10px;
    }
  }
}
