.stand-analytics {
  padding: 27px 32px;
  flex: 1;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  &__title {
    margin-right: 15px;
    font-weight: 500;
    font-size: 24px;
    color: rgba(45, 45, 45, 1);
  }
  &__download-btn {
  }
  &__posttitle {
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
    margin-bottom: 16px;
  }
  &__main {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
  }
  &__first-column {
    margin-right: 17px;
    flex: 617px;
    @media (max-width: 1200px) {
      flex: 100%;
      margin-right: 0;
    }
  }

  &__second-column {
    flex: 400px;
    @media (max-width: 1200px) {
      flex: 100%;
    }
  }
  &__stand-info {
  }
  @media (max-width: 900px) {
    padding-bottom: 80px;
  }
}

.analytics-stand-info {
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  min-height: 185px;
  margin-bottom: 17px;
  &__img {
    margin-right: 17px;
    height: 160px;
    max-width: 235px;
    border-radius: 0px 20px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    & img {
      border-radius: inherit;
      width: 100%;
      height: auto;
    }
  }
  &__main {
    flex: 1;
  }
  &__title {
    font-size: 18px;
    color: rgba(32, 37, 49, 1);
    margin-bottom: 4px;
  }
  &__date {
    font-size: 13px;
    color: rgba(32, 37, 49, 1);
    margin-bottom: 12px;
    & span {
      color: rgba(114, 126, 142, 1);
    }
  }
  &__about {
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
  }
}

.analytics-duration {
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  padding: 26px;
  margin-bottom: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__title {
    color: rgba(114, 126, 142, 1);
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
  }
  &__time {
    text-align: center;
    font-size: 13px;
    color: #3f4551;
    & span {
      font-size: 32px;
      font-weight: 500;
    }
  }
}

.analytics-new-visitors {
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  padding: 26px;
  margin-bottom: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__title {
    color: rgba(114, 126, 142, 1);
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
  }
  &__count {
    font-size: 13px;
    color: #3f4551;
    text-align: center;
    & span {
      font-size: 32px;
      font-weight: 500;
    }
  }
}

.analytics-contacts {
  border: 1px solid #e9ecf5;
  border-radius: 4px;
  margin-bottom: 16px;
  &__header {
    padding: 11px 14px;
    background: #f7f8fc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 18px;
    & span {
      font-size: 13px;
      color: rgba(114, 126, 142, 1);
      margin-left: 8px;
      padding: 3px 12px;
      background: #ffffff;
      border: 1px solid #e9ecf5;
      border-radius: 15px;
    }
  }
  &__more-btn {
    background: #ffffff;
    border: 1px solid #e9ecf5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }
  &__main {
    padding: 16px;
  }
  &__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__avatar {
    flex: 0 32px;
    border-radius: 50%;
    height: 32px;
    margin-right: 12px;
  }
  &__fullname {
    flex: 2;
    font-size: 13px;
    color: rgba(63, 69, 81, 1);
  }
  &__mail {
    flex: 2;
    color: rgba(114, 126, 142, 1);
    font-size: 13px;
  }
  &__phone {
    flex: 2;
    color: rgba(114, 126, 142, 1);
    font-size: 13px;
    text-align: center;
  }
  &__client-type {
    flex: 1;
    font-size: 13px;
    color: rgba(114, 126, 142, 1);
    position: relative;
    &:before {
      background-color: #727e8e;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      left: -15px;
      top: 50%;
      transform: translate(0, -50%);
      display: block;
      content: "";
    }
    &--new {
      &::before {
        background-color: #3b99cc;
      }
    }
  }
}
.contacts-nav{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  &__left, &__right{
    opacity: 0.6;
    width: 50px;
    &:hover{
      opacity: 0.9;
    }
  }
  &__disactive{
    opacity: 0.3;
    &:hover{
      opacity: 0.3;
    }
  }
}