.event-item {
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 20px;
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    background: rgba(242, 201, 76, 0.2);
  }

  &.actual {
    background: rgba(255, 138, 0, 0.1);
  }

  &__date {
    padding: 3px 0 12px;
    font-family: "Oswald", sans-serif;
    flex: 0;
    text-align: center;
  }
  &__dash {
    line-height: 10px;
  }

  &__day {
    color: #c90113;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;

    padding-bottom: 9px;
    margin-bottom: 5px;
    border-bottom: 1px solid #dde0e6;
  }

  &__week {
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }

  &__main {
    width: 80%;
    padding: 0 20px;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: #3f4551;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-right: 10px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &__type {
    display: inline-block;
    background: #e9ecf5;
    border-radius: 15px;
    font-size: 13px;
    color: #727e8e;
    padding: 3px 12px;
    margin-right: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  &__places {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 15px;
    padding: 3px 12px;
    margin-right: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-top: 2px;
    margin-bottom: 2px;

    &.warning {
      color: #ff8a00;
      border: 0.5px solid #ff8a00;
    }
    &.safe {
      color: #27ae60;
      border: 0.5px solid #27ae60;
    }
    &.danger {
      color: #c90113;
      border: 0.5px solid #c90113;
    }
  }

  &__text {
    margin-top: 12px;
    color: #727e8e;
    font-weight: 400;
    font-size: 13px;
  }

  &__poster {
    flex: 0 0 200px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;

    img {
      width: 100%;
      border-bottom-left-radius: inherit;
      border-top-right-radius: inherit;
    }
  }

  &__month {
    color: #727e8e;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 18px;
  }

  &__month--actual {
    margin-top: 24px;
    padding-left: 10px;
  }
}

@media (max-width: 1400px) {
  .event-item {
    padding: 15px;
    justify-content: center;
    margin: 0 0 20px;

    &__header {
      flex-wrap: wrap;
    }
    &__title {
      flex: 100%;
    }

    &__poster {
      flex: 0 0 170px;
    }

    &__info {
      flex: 100%;
      justify-content: flex-start;
      margin-top: 7px;
    }

    &__date {
      margin-right: 10px;
    }

    &__title {
      text-align: left;
    }
    &__poster {
    }
  }
}

@media (max-width: 800px) {
  .event-item {
    padding: 5px;
    flex-wrap: wrap;

    &__main {
      flex: 1 70%;
    }

    &__date {
      flex: 0 10%;
    }

    &__day {
      font-size: 18px;
      line-height: 1.3;
    }

    &__header {
      font-size: 18px;
    }

    &__main {
      margin-bottom: 18px;
    }

    &__poster {
      flex: 0 0 200px;
      height: 98px;
    }

    &__type {
    }

    &__header {
      text-align: center;
      width: 100%;
    }

    &__text {
      width: 100%;
    }

    &:last-child {
      padding-bottom: 64px;
    }
  }
}
