.contacts-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 50px;

  &__item {
    width: 49%;
    border: 1px solid #e9ecf5;
    border-radius: 4px;

    margin-bottom: 15px;
  }

  &__item--documents {
    width: 100%;
    margin-bottom: 30px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 15px 25px;
    background: #f7f8fc;
    align-items: center;
    & .btns-group {
    }
  }

  &__avatar {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      height: 100%;
      border-radius: 30px;
      margin-right: 0 !important;
    }
  }

  &__avatar--participants {
    width: 50%;
    height: 100%;
  }

  &__info {
    width: 82%;
  }

  &__name {
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    margin-bottom: 5px;
  }

  &__name--participants {
    width: 100%;
    margin-bottom: 0;
    margin-left: 15px;
  }

  &__address {
    color: #727e8e;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
  }

  &__main {
    padding: 15px;
  }

  &__main--documents {
    display: flex;
    justify-content: space-between;
  }

  &__data {
    display: flex;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    justify-content: space-between;
  }

  &__data--promotion {
    .contacts-cards__point {
      width: 40%;
    }
    .contacts-cards__resonse {
      width: 60%;
    }
  }

  &__point {
    color: #727e8e;
    margin-right: 5px;
    flex: 1 20%;
  }

  &__response {
    color: #3f4551;
    word-break: break-all;
    flex: 6;
    margin-left: 7px;
  }

  &__response--documents {
    width: 50%;
  }

  &__response--email {
    cursor: pointer;
    color: #3b99cc;

    &:hover {
      text-decoration: underline;
    }
  }
  &__response--promotion {
    color: #3f4551;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
  }
}

.participants-cards__info {
  display: flex;
  align-items: center;
  height: 100%;
  & img {
    margin-right: 10px;
    max-height: 100%;
  }
}

.participants-cards__info--documents {
  width: auto;
}

.participants-cards__part {
  padding: 10px 0px 15px 15px;
  flex: 1 45%;
  padding-bottom: 0px;
}

.participants-cards__part--small {
  padding: 10px 0px 15px 15px;
  flex: 35%;
  padding-bottom: 0px;
}
