.create-stand-price {
  margin-top: 20px;
  &__title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 7px;
    color: rgba(32, 37, 49, 1);
  }
  &__main {
    border-bottom: 1px solid #e9ecf5;
    padding-bottom: 16px;
  }
  &__info-item {
    display: flex;
    align-items: center;
    max-width: 170px;
    color: rgba(114, 126, 142, 1);
    font-size: 13px;
    margin-bottom: 8px;
    & span {
      color: rgba(32, 37, 49, 1);
    }
    &--price {
      margin-top: 16px;
      & span {
        font-weight: 700;
      }
    }
  }
  &__info-name {
    flex: 1;
  }
}
