.stand-documents {
  flex: 1;
  padding: 30px;

  &__header {
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    color: rgba(45, 45, 45, 1);
    margin-right: 110px;
  }

  &__search-input {
    flex: 1;
    position: relative;
    & input {
      padding: 12px 17px;
      width: 100%;
      border: 1px solid #e9ecf5;
      border-radius: 4px;
      font-size: 13px;
      background-color: transparent;
    }
    & img {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translate(0, -50%);
      z-index: -1;
    }
  }
}
