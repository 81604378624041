.chatBox {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__main {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    height: 100%;
    overflow: hidden;
  }

  &__control-panel {
    align-self: flex-end;
    margin-right: 28px;
    margin-bottom: 23px;
    margin-left: 40px;
    z-index: 50;
    user-select: none;

    @media (max-width: 650px) {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 200px;
      margin: 0;
    }
  }

  &__side-panel {
    align-self: flex-end;
    flex: 0 0 383px;
    display: flex;
    justify-content: flex-end;
    max-height: 100%;
    z-index: 50;
    position: fixed;
    right: -2px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 650px) {
      flex: 1;
      justify-content: center;
    }
  }
}

.chatBox-sidebar {
  width: 100%;
  background-color: #fff;
  max-height: 100%;
  overflow-y: auto;
  border-radius: 4px;
  position: relative;
  &__header {
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: #fff;
    border-radius: 4px 4px 0px 0px;
    @media (max-width: 650px) {
      width: 100vw;
    }
  }

  &__links {
  }

  &__header-link {
    font-weight: 500;
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid rgba(233, 236, 245, 1);
    margin-right: 12px;
    border-radius: 30px;
    color: rgba(59, 153, 204, 1);
    &--active {
      color: rgba(63, 69, 81, 1);
    }
  }

  &__close-btn {
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }

  &__main {
    padding-top: 73px;
    padding-bottom: 10px;
  }
}

