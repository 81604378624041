.web-badge {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 20px;
  cursor: pointer;
  &--active {
    box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 0 7px rgba(103, 108, 111, 1);
  }
}

@media (max-width: 1400px) {
  .web-badge {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
}

@media (max-width: 1100px) {
  .web-badge {
    width: 15px;
    height: 15px;
  }
}
