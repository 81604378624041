.event-location {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  height: 100vh;
  overflow: hidden;
  &__frame {
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(1, 1.12);
  }
  &__frame3d {
    display: block;
    width: 100%;
    height: 100%;
  }
}
