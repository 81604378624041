
.events-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__html {
    iframe {
      width: 100%;
    }
  }

  &__main {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    height: 100%;
    overflow: hidden;
  }

  &__control-panel {
    align-self: flex-end;
    margin-right: 28px;
    margin-bottom: 6vh;
    margin-left: 40px;
    z-index: 50;
    user-select: none;

    @media (max-width: 1156px) {
      margin-right: -40px;
    }

    @media (max-width: 1000px) {
      margin-right: 28px;
    }

    @media (max-width: 650px) {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 220px;
      margin: 0;
      margin-right: 28px;
    }
  }

  &__side-panel {
    align-self: flex-end;
    flex: 0 0 383px;
    display: flex;
    justify-content: flex-end;
    max-height: 100%;
    z-index: 50;
    position: relative;

    @media (max-width: 650px) {
      flex: 1;
      justify-content: center;
    }
  }
}

.modal3d {
  width: 100vw;
  height: 100vh;
  margin: auto;
  background-color: rgba(0,0,0, .4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transform: scale(0);
}
.modal3d.active {
  transform: scale(1);
}

.modal3d__content {	
  background-color: rgba(128, 128, 128, .7);
  z-index: 3;
}
.modal3d__content img {
  width: 90vw;
  max-height: 65vh;
  object-fit: contain;
}
 .modal3d__content iframe, .modal3d__content embed {
   width: 90vw;
   height: 65vh;
}
