.demo-main {
  z-index: 100;
  max-width: 100vw;
  position: relative;
  background: #646e7b;
  height: 555px;

  &__overlay {
    max-width: 100vw;
    overflow-y: hidden;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 555px;
    z-index: 10;
    height: 100%;
    background: linear-gradient(269.86deg, rgba(100, 110, 123, 0) 71.92%, #646e7b 99.9%);
    opacity: 0.75;
  }

  &__content {
    position: relative;
    z-index: 1000;
    padding: 0px 15px;
  }

  &__nav {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    margin-bottom: 140px;
    align-items: center;

    @media (max-width: 670px) {
      justify-content: flex-end;
    }

    @media (max-width: 650px) {
      display: none;
      &--part {
        display: flex;
      }
    }

    &-main {
      width: 60%;
      display: flex;

      @media (max-width: 850px) {
        width: 70%;
      }

      @media (max-width: 740px) {
        width: 80%;
      }

      @media (max-width: 670px) {
        display: none;
      }
    }

    &__item {
      color: #ffffff;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.2;
      margin-right: 40px;

      cursor: pointer;

      &:hover {
        color: #ffffff;
        text-decoration: underline;
      }

      &.contacts {
        &:hover {
          color: #ffffff;
          text-decoration: none;
          cursor: initial;
        }
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    color: #ffffff;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    opacity: 0.7;

    margin-bottom: 30px;
  }

  &__info {
  }
}

.demo {
  overflow: hidden;

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__container {
    z-index: 1;
    width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media (max-width: 950px) {
      width: 100%;
    }

    &--program {
      max-width: 791px;
      align-items: center;
    }
  }

  &-header {
    background: #202531;
    padding: 15px 0;
    position: relative;

    &__text {
      font-size: 11px;
      line-height: 16px;
      color: #fff;
    }

    &__main {
      width: 100%;
      display: flex;
      align-items: center;

      &:hover .demo-header__text {
        text-decoration: underline;
      }
    }

    &__back {
      margin-right: 10px;
    }

    &__logo {
      margin-right: 15px;
    }

    @media (max-width: 950px) {
      padding-left: 10px;
    }
  }

  &-bg {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      min-height: 100%;
    }
  }

  .demo-info {
    @media (max-width: 950px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    max-width: 100vw;
  }

  &-section {
    padding-top: 40px;
    &:last-child {
      padding-bottom: 40px;
    }
    @media (max-width: 450px) {
      padding-top: 20px;
    }

    &__title {
      color: #e84f13;
      font-weight: 500;
      font-size: 24px;
      line-height: 48px;

      text-align: center;
    }

    &__text {
      color: #676c6f;
      font-size: 16px;
      line-height: 22px;

      &.about {
        @media (max-width: 450px) {
          margin: 0 auto;
        }
      }
    }

    &__text--advantages {
      font-weight: bold;
    }
  }

  &-about__main {
    display: flex;
    padding-top: 20px;

    @media (max-width: 670px) {
      flex-wrap: wrap;
    }
  }

  &-about__logo {
    max-width: 221px;
    margin-right: 30px;
    max-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      max-width: 100%;
    }

    @media (max-width: 650px) {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }

  &-advantages__main {
    margin: 0 auto;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-advantages__item {
    display: flex;
    align-items: baseline;
    width: 100%;
    margin-bottom: 10px;

    i {
      margin-right: 10px;
    }
  }

  &-products__main {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 20px;
  }

  &-product__item {
    position: relative;
    width: 20%;
    margin-bottom: 30px;
    margin-right: 20px;
    margin-left: 20px;

    @media (max-width: 800px) {
      width: 30%;
    }

    @media (max-width: 550px) {
      width: 43%;
      margin-right: 10px;
    }

    @media (max-width: 430px) {
      width: 100%;
    }
  }

  &-product__photo {
    margin-bottom: 5px;
    border-radius: 4px;
    width: auto;
    width: 100%;
    height: auto;

    @media (max-width: 430px) {
      display: block;
      margin: 0 auto;
    }
  }

  &-product__name {
    color: #3f4551;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    @media (max-width: 430px) {
      text-align: center;
    }
  }

  &-product__desc {
    font-size: 16px;
    line-height: 20px;
    color: #676c6f;
    margin-top: 5px;
    overflow-wrap: break-word;
  }

  &-footer {
    background: #545862;
    padding-top: 35px;
    padding-bottom: 35px;
    font-weight: 400;
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;

    @media (max-width: 950px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &__main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
    &__info {
      margin-right: 15px;
      max-width: 276px;
      @media (max-width: 750px) {
        flex: 100%;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
    &__contacts {
      display: flex;
      flex-direction: column;
      @media (max-width: 750px) {
        flex: 100%;
      }
      & span {
        line-height: 22px;
      }
    }
  }
}

.demo-event-content {
  position: relative;
  z-index: 1000;
  @media (max-width: 950px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 650px) {
  }

  &__btn {
    &:hover {
      filter: brightness(0.93);
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -80px;
    position: relative;
    @media (max-width: 650px) {
      margin-top: 25px;
      flex-wrap: wrap;
    }
  }

  &__logo {
    flex: 0 0 210px;
    img {
      width: 100%;
      max-width: 210px;
      height: auto;
    }
    @media (max-width: 650px) {
      order: 1;
      flex: 100%;
      justify-content: center;
      margin-bottom: 25px;
      display: flex;
    }
  }

  &__info {
    flex: 1 554px;
    @media (max-width: 650px) {
      order: 2;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 0;
      align-items: center;
    }
  }

  &__date {
    text-align: center;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    padding: 10px 16px;
    color: #ffffff;
    margin-bottom: 40px;
    max-width: max-content;
    min-width: max-content;
    @media (max-width: 650px) {
      margin-bottom: 15px;
      font-size: 18px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 48px;
    color: #ffffff;
    margin-bottom: 15px;

    @media (max-width: 750px) {
      width: 100%;
    }
    @media (max-width: 650px) {
      text-align: center;
      font-size: 24px;
    }
  }

  &__subtitle {
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    opacity: 0.9;
    @media (max-width: 650px) {
      text-align: center;
      font-size: 14px;
    }
  }

  &__buttons {
    margin-top: 40px;
    width: 300px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 650px) {
      margin-top: 64px;
    }
  }
}

.demo-event-main {
  position: relative;
  max-width: 100vw;
  z-index: 100;
  min-height: 554px;
  padding-bottom: 69px;
}

.demo-event {
  &__container {
    position: relative;
    width: 900px;
    margin: 0 auto;
    z-index: 1;
  }

  &-bg {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: -1;
    height: 100%;
    top: 0;
    width: 100%;

    img {
      width: 100%;
      min-height: 100%;
    }
  }
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(81.85deg, #0083c7 -0.85%, #09aaa4 112.35%);
    opacity: 0.75;
  }

  &__about {
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: 950px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &-title {
      text-align: center;
      color: #09aaa4;
      font-weight: 500;
      font-size: 24px;
      line-height: 48px;
    }

    &-text {
      display: block;
      width: 90%;
      color: #676c6f;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin: 0 auto;
      text-align: center;
    }
  }

  &__participants {
    padding-top: 35px;
    padding-bottom: 35px;
    background-image: url("../../assets/img/demoEventParticipantsBg.png");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: cover;
    background-color: #0083c7;

    @media (max-width: 950px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    &-main {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    &-title {
      color: #ffffff;
      font-weight: 500;
      font-size: 24px;
      line-height: 48px;

      text-align: center;
      margin-bottom: 20px;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border-radius: 4px;
      width: 30%;
      height: 200px;
      margin-right: 3%;
      overflow: hidden;

      padding: 15px;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 800px) {
        width: 31%;
      }

      @media (max-width: 450px) {
        width: 45%;
      }
    }
  }

  &__program {
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: 950px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 48px;
      color: #09aaa4;
      text-align: center;

      margin-bottom: 20px;
    }

    &-item {
      &-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 38px;
        color: #2d2d2d;
        margin-bottom: 15px;
      }
      &-content {
        display: flex;
        align-items: center;

        margin-bottom: 20px;
      }
    }

    &-time {
      min-width: 134px;
      margin-right: 50px;
      display: flex;

      & span {
        color: #676c6f;
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
        margin-right: 3px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-event {
      flex: 1;
      color: #676c6f;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__program-main {
    display: flex;
    flex-direction: column;
  }

  &__meeting {
    padding-top: 50px;
    padding-bottom: 50px;
    background: rgba(0, 131, 199, 0.05);

    @media (max-width: 950px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &-title {
      color: #09aaa4;
      font-weight: 500;
      font-size: 24px;
      line-height: 48px;

      text-align: center;
      margin-bottom: 30px;
    }

    &-main {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
      justify-content: center;
      grid-gap: 20px;
    }

    &-item {
      text-align: center;
      margin-bottom: 15px;
    }

    &-avatar {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      width: 147px;
      height: 147px;
      margin: 0 auto;
      margin-bottom: 15px;
      background: #ffffff;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--no-image {
        & img {
          width: 80px;
        }
      }
    }

    &-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;

      color: #676c6f;
    }

    &-pos {
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      color: #727e8e;
    }
  }
}

.demo-presentation {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 38px;
  border: 1px solid #e9ecf5;
  margin-top: 20px;
  margin-bottom: 50px;
  cursor: pointer;
  color: #e84f13;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  & img {
    margin-right: 20px;
  }

  &:hover {
    color: darken(#e84f13, 15%);
  }

  &__pdf {
    padding-right: 20px;
  }
}
